import { BrowserRouter } from "react-router-dom";
import { Fragment } from "react";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import LoginPage from "./login.page";
import RegistroPage from "./registro.page";
import ZonaUsuarioPage from "./zona-usuario.page";

export default function AppPage() {
  const [_mounted, _setMounted] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const detectMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  useEffect(() => {
    _setMounted(true);
    setIsMobile(detectMobile());
    return () => {
      _setMounted(false);
    };
  }, []);

  if (_mounted === null) {
    return <p>Cargando...</p>;
  }

  if (!_mounted) {
    return <p>Error</p>;
  }

  return (
    <Fragment>
      <BrowserRouter basename="/">
        <Routes>
          <Route index element={<LoginPage isMobile={isMobile} />} />
          <Route path="login" element={<LoginPage isMobile={isMobile} />} />
          <Route path="registro" element={<RegistroPage />} />
          <Route path="zona-usuario" element={<ZonaUsuarioPage />} />
          <Route path="*" element={<p>P&aacute;gina no econtrada</p>} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}
