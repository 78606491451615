import { Layout } from "antd";
import { Col } from "antd";
import { Row } from "antd";
import { Fragment } from "react";
import logo from "../../../assets/logo.svg";
import "./header.styles.css";

export default function Header() {
  const { Header } = Layout;

  return (
    <Fragment>
      <Header>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={12}
            xxl={12}
          >
            <img
              src={logo}
              alt="3AAMSEQ SA"
              title="3AAMSEQ"
              style={{ width: 200 }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={12}
            xxl={12}
            className="col-link-header"
          >
            <span>
              En caso de necesitar ayuda escr&iacute;benos a&nbsp;
              <a
                href="mailto:postventa@3aamseq.com.pe"
                rel="noreferrer"
                target="_blank"
              >
                postventa@3aamseq.com.pe
              </a>
            </span>
          </Col>
        </Row>
      </Header>
    </Fragment>
  );
}
