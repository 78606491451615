import "./fab.styles.css";
import { WhatsAppOutlined } from "@ant-design/icons";

export default function FloatingButton() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=51902719446"
      target="_blank"
      rel="noreferrer"
      className="fab"
    >
      <WhatsAppOutlined className="fab-icon" />
      <p>
        Comun&iacute;cate <br />
        con nosotros
      </p>
    </a>
  );
}
