import { Distrito } from "./distrito.model";

export interface UsuarioDataI {
  codigo: string;
  tipoDocumento: string;
  numeroDocumento: string;
  nombres: string;
  apellidos: string;
  celular: string;
  email: string;
  fechaNacimiento: string;
  distrito: Distrito;
  sede: string;
  politicas: boolean;
  terminos: boolean;
  uso: boolean;
  fechaRegistro: string;
  estado: number;
}

export class Usuario implements UsuarioDataI {
  codigo: string;
  tipoDocumento: string;
  numeroDocumento: string;
  nombres: string;
  apellidos: string;
  celular: string;
  email: string;
  fechaNacimiento: string;
  distrito: Distrito;
  sede: string;
  politicas: boolean;
  terminos: boolean;
  uso: boolean;
  fechaRegistro: string;
  estado: number;

  constructor(data: UsuarioDataI) {
    this.codigo = data.codigo;
    this.tipoDocumento = data.tipoDocumento;
    this.numeroDocumento = data.numeroDocumento;
    this.nombres = data.nombres;
    this.apellidos = data.apellidos;
    this.celular = data.celular;
    this.email = data.email;
    this.fechaNacimiento = data.fechaNacimiento;
    this.sede = data.sede;
    this.distrito = data.distrito;
    this.politicas = data.politicas;
    this.terminos = data.terminos;
    this.uso = data.uso;
    this.fechaRegistro = data.fechaRegistro;
    this.estado = data.estado;
  }

  _toMap() {
    return {
      codigo: this.codigo,
      tipo_documento: this.tipoDocumento,
      numero_documento: this.numeroDocumento,
      nombres: this.nombres,
      apellidos: this.apellidos,
      celular: this.celular,
      email: this.email,
      fecha_nacimiento: this.fechaNacimiento,
      distrito: this.distrito,
      sede: this.sede,
      politicas: this.politicas,
      terminos: this.terminos,
      uso: this.uso,
    };
  }
}
