import "./styles/index.css";
import "antd/dist/antd.min.css";
import { CookiesProvider } from "react-cookie";
import AppPage from "./ui/page/app.page";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CookiesProvider>
    <AppPage />
  </CookiesProvider>
);
