import { APIResponse } from "../../model/apiresponse";
import { APIUsuario } from "../../api/usuario.api";
import { Fragment } from "react";
import { Layout } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FloatingButton from "../widget/fab/fab.widget";
import Footer from "../widget/footer/footer.widget";
import Header from "../widget/header/header.widget";
import UsuarioContext from "../../provider/usuario_provider";
import ZonaUsuario from "../components/zona-usuario/zona-usuario.component";
import { Usuario } from "../../model/usuario.model";
import { Modal } from "antd";
import { APICupon } from "../../api/cupon.api";
import CuponContext from "../../provider/cupon_provider";
import { Cupon } from "../../model/cupon.model";

export default function ZonaUsuarioPage() {
  const [_mounted, _setMounted] = useState<any>(null);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const _apiCupon = useContext<APICupon>(CuponContext);
  const [_usuario, _setUsuario] = useState<Usuario>();
  const [_modal, _contextHolder] = Modal.useModal();
  const [_listaCupones, _setListaCupones] = useState<Cupon[]>([]);
  const { Content } = Layout;
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    let _response: APIResponse | null = await _apiUsuario._getCurrentUser();
    if (_response?.success) {
      _setUsuario(_response.data);
    } else {
      _navigate("/");
    }
  }, [_apiUsuario, _navigate]);

  const _logout = async () => {
    await _apiUsuario._logout();
    _navigate("/");
  };

  const _editarUsuario = (usuario: Usuario) => {
    _modal.confirm({
      title: "3A AMSEQ",
      content: "¿Está seguro de actualizar sus datos?",
      centered: true,
      okText: "Si, actualizar",
      cancelText: "No, cancelar",
      onOk: async () => {
        let _response: APIResponse | null = await _apiUsuario._editarUsuario(
          usuario
        );
        if (_response?.success) {
          await _apiUsuario.refreshToken();
          _getCurrentUser();
          _modal.success({
            title: "3A AMSEQ",
            content: _response.message,
            centered: true,
          });
        } else {
          _modal.error({
            title: "3A AMSEQ",
            content: _response?.message,
            centered: true,
          });
        }
      },
    });
  };

  const _registrarCupon = (
    cupon: string,
    monto: number,
    fecha: string,
    entry: string
  ) => {
    _modal.confirm({
      title: "3A AMSEQ",
      content: "¿Está seguro de registrar el cupón?",
      centered: true,
      okText: "Si, registrar",
      cancelText: "No, cancelar",
      onOk: async () => {
        let _response: APIResponse | null = await _apiCupon._registrarCupon(
          cupon,
          monto,
          fecha,
          entry
        );
        _listarCupones();
        if (_response?.success) {
          _modal.success({
            title: "3A AMSEQ",
            content: _response.message,
            centered: true,
          });
        } else {
          _modal.error({
            title: "3A AMSEQ",
            content: _response?.message,
            centered: true,
          });
        }
      },
    });
  };

  const _listarCupones = useCallback(async () => {
    _setListaCupones([]);
    await _apiCupon._listarCupones().then((data: Cupon[] | null) => {
      _setListaCupones(data!);
    });
  }, [_apiCupon]);

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    _listarCupones();
    document.title = "3A AMSEQ - Perfil de usuario";
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser, _listarCupones]);

  if (_mounted === null) {
    return <p>Cargando...</p>;
  }

  if (!_mounted) {
    return <p>Error</p>;
  }
  return (
    <Fragment>
      <Layout>
        <Header />
        <Content>
          <ZonaUsuario
            usuario={_usuario!}
            editarUsuario={_editarUsuario}
            registrarCupon={_registrarCupon}
            listaCupones={_listaCupones}
            logout={_logout}
          />
        </Content>
        <Footer />
        <FloatingButton />
      </Layout>
      {_contextHolder}
    </Fragment>
  );
}
