import { APIResponse } from "../model/apiresponse";
import { AxiosResponse } from "axios";
import { Cookies } from "react-cookie";
import { decodeToken } from "react-jwt";
import { Usuario } from "../model/usuario.model";
import axios from "axios";
import Global from "../global/global";

export class APIUsuario {
  async _registrarUsuario(_usuario: Usuario): Promise<APIResponse | null> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/registrarUsuario`,
      _usuario._toMap()
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    }
    return null;
  }

  async _editarUsuario(_usuario: Usuario): Promise<APIResponse | null> {
    let data: any = _usuario._toMap();
    data.token = await this.getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/editarUsuario`,
      data
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    }
    return null;
  }

  async _login(documento: string): Promise<APIResponse | null> {
    let result: AxiosResponse = await axios.post(`${Global.URL_API}/login`, {
      documento,
    });
    if (result.status === 200) {
      let response: APIResponse = new APIResponse(result.data);
      if (response.success && decodeToken(response.data)) {
        let _token: any = decodeToken(response.data);
        this.saveToken(response.data);
        return new APIResponse({
          data: new Usuario(_token.usuario),
          message: response.message,
          success: response.success,
        });
      } else {
        return response;
      }
    }
    return null;
  }

  async _getCurrentUser(): Promise<APIResponse | null> {
    let _cookie: string = await this.getToken();
    if (_cookie && decodeToken(_cookie)) {
      let _token: any = decodeToken(_cookie);
      if (_token) {
        return new APIResponse({
          data: new Usuario(_token.usuario),
          success: true,
        });
      }
    }
    return null;
  }

  async saveToken(data: string): Promise<void> {
    let cookie: Cookies = new Cookies();
    cookie.set("_token", data);
  }

  async getToken(): Promise<string> {
    let cookie: Cookies = new Cookies();
    return cookie.get("_token");
  }

  async refreshToken(): Promise<void> {
    let response: APIResponse | null = await this._getCurrentUser();
    let usuario: Usuario = new Usuario(response?.data);
    await this._login(usuario.numeroDocumento);
  }

  async _logout(): Promise<void> {
    let cookie: Cookies = new Cookies();
    cookie.remove("_token");
  }
}
