import "./registro.styles.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { Col } from "antd";
import { DatePicker } from "antd";
import { Departamento } from "../../../model/departamento.model";
import { Distrito } from "../../../model/distrito.model";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Provincia } from "../../../model/provincia.model";
import { Row } from "antd";
import { Sede } from "../../../model/sede.model";
import { Select } from "antd";
import { Space } from "antd";
import { Typography } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { Usuario } from "../../../model/usuario.model";
import locale from "antd/es/date-picker/locale/es_ES";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

export default function Registro({
  departamentos,
  provincias,
  distritos,
  listarProvincias,
  listarDistritos,
  registrarUsuario,
  goLogin,
}: {
  departamentos: Departamento[];
  provincias: Provincia[];
  distritos: Distrito[];
  listarProvincias: Function;
  listarDistritos: Function;
  registrarUsuario: Function;
  goLogin: Function;
}) {
  const [_form] = Form.useForm();
  const [_politicasChecked, _setPoliticasChecked] = useState<boolean>(false);
  const [_terminosChecked, _setTerminosChecked] = useState<boolean>(false);
  const [_usoChecked, _setUsoChecked] = useState<boolean>(false);
  const { Option } = Select;
  const { Title } = Typography;
  const [_sedes] = useState<Sede[]>([
    new Sede({
      codigo: "Trujillo I - La Rinconada",
      nombre: "Trujillo I - La Rinconada",
    }),
    new Sede({
      codigo: "Trujillo II - El Milagro",
      nombre: "Trujillo II - El Milagro",
    }),
    new Sede({
      codigo: "Chiclayo I - Av. Augusto B. Leguía",
      nombre: "Chiclayo I - Av. Augusto B. Leguía",
    }),
    new Sede({
      codigo: "Chiclayo II - Chosica del Norte",
      nombre: "Chiclayo II - Chosica del Norte",
    }),
    new Sede({
      codigo: "Piura I - Av. Sánchez Cerro",
      nombre: "Piura I - Av. Sánchez Cerro",
    }),
    new Sede({
      codigo: "Piura II - Carretera Piura-Sullana",
      nombre: "Piura II - Carretera Piura-Sullana",
    }),
    new Sede({ codigo: "Chimbote", nombre: "Chimbote" }),
    new Sede({ codigo: "Lima SJL", nombre: "Lima SJL" }),
    new Sede({ codigo: "Lima Pte. Piedra", nombre: "Lima Pte. Piedra" }),
    new Sede({ codigo: "Lima VES", nombre: "Lima VES" }),
    new Sede({ codigo: "Arequipa", nombre: "Arequipa" }),
    new Sede({ codigo: "Huancayo", nombre: "Huancayo" }),
    new Sede({
      codigo: "Tarapoto I - Av. De Evitamiento, La Banda de Shilcayo",
      nombre: "Tarapoto I - Av. De Evitamiento, La Banda de Shilcayo",
    }),
    new Sede({
      codigo: "Tarapoto II - Av. Salaverry, Morales",
      nombre: "Tarapoto II - Av. Salaverry, Morales",
    }),
  ]);

  const _onFinish = (e: any) => {
    registrarUsuario(new Usuario(_form.getFieldsValue()));
  };

  useEffect(() => {
    _form.setFieldsValue({
      tipoDocumento: "DNI",
      uso: _usoChecked,
    });
  });

  return (
    <Fragment>
      <div className="div-form-registro">
        <Space direction="vertical">
          <div className="divBtnVolver">
            <button
              className="btnVolver"
              onClick={() => {
                goLogin();
              }}
            >
              <Space>
                <ArrowLeftOutlined />
                VOLVER
              </Space>
            </button>
          </div>
          <Form
            name="form"
            layout="vertical"
            onFinish={_onFinish}
            autoComplete="off"
            form={_form}
            requiredMark={false}
          >
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Title level={3} className="title">
                  REG&Iacute;STRATE Y PARTICIPA
                </Title>
                <Title level={5} className="subtitle">
                  Completa el siguiente formulario y participa en los sorteos de
                  espectaculares premios
                </Title>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Tipo de documento
                    </Title>
                  }
                  name="tipoDocumento"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "string",
                    },
                  ]}
                >
                  <Select>
                    <Option value={"DNI"} key={"DNI"}>
                      DNI
                    </Option>
                    <Option value={"C.E"} key={"C.E"}>
                      C.E
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      N&uacute;mero de documento de identidad
                    </Title>
                  }
                  name="numeroDocumento"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                    },
                  ]}
                >
                  <Input
                    placeholder="N° de documento"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    type="number"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Nombres
                    </Title>
                  }
                  name="nombres"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "string",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Apellidos
                    </Title>
                  }
                  name="apellidos"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "string",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Celular
                    </Title>
                  }
                  name="celular"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                    },
                  ]}
                >
                  <Input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onWheel={(event) => event.currentTarget.blur()}
                    type="number"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Email personal
                    </Title>
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Title level={5} className="title-section">
                  Fecha de nacimiento
                </Title>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="fechaNacimiento"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "date",
                    },
                  ]}
                >
                  <DatePicker
                    locale={locale}
                    format={"YYYY-MM-DD"}
                    inputReadOnly
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Title level={5} className="title-section">
                  Domicilio
                </Title>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Departamento
                    </Title>
                  }
                  name="departamento"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "number",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona tu departamento"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(_input: any, _option: any) =>
                      _option.children
                        .toLowerCase()
                        .indexOf(_input.toLowerCase()) >= 0
                    }
                    filterSort={(_optionA: any, _optionB: any) =>
                      _optionA.children
                        .toLowerCase()
                        .localeCompare(_optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                      listarProvincias(value);
                    }}
                    disabled={departamentos.length === 0}
                    loading={departamentos.length === 0}
                  >
                    {departamentos.map(
                      (departamento: Departamento, index: number) => {
                        return (
                          <Option
                            value={departamento.codigo}
                            key={`${index}-${departamento.codigo}`}
                          >
                            {departamento.nombre}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Provincia
                    </Title>
                  }
                  name="provincia"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "number",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona tu provincia"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(_input: any, _option: any) =>
                      _option.children
                        .toLowerCase()
                        .indexOf(_input.toLowerCase()) >= 0
                    }
                    filterSort={(_optionA: any, _optionB: any) =>
                      _optionA.children
                        .toLowerCase()
                        .localeCompare(_optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                      listarDistritos(value);
                    }}
                    disabled={provincias.length === 0}
                    loading={provincias.length === 0}
                  >
                    {provincias.map((_provincia: Provincia, index: number) => {
                      return (
                        <Option
                          value={_provincia.codigo}
                          key={`${index}-${_provincia.codigo}`}
                        >
                          {_provincia.nombre}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      Distrito
                    </Title>
                  }
                  name="distrito"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "number",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona tu distrito"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(_input: any, _option: any) =>
                      _option.children
                        .toLowerCase()
                        .indexOf(_input.toLowerCase()) >= 0
                    }
                    filterSort={(_optionA: any, _optionB: any) =>
                      _optionA.children
                        .toLowerCase()
                        .localeCompare(_optionB.children.toLowerCase())
                    }
                    disabled={distritos.length === 0}
                    loading={distritos.length === 0}
                  >
                    {distritos.map((_distrito: Distrito, index: number) => {
                      return (
                        <Option
                          value={_distrito.codigo}
                          key={`${index}-${_distrito.codigo}`}
                        >
                          {_distrito.nombre}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={
                    <Title level={5} className="form-label">
                      ¿En qu&eacute; sede realizas tus compras?
                    </Title>
                  }
                  name="sede"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "string",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selecciona tu sede"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(_input: any, _option: any) =>
                      _option.children
                        .toLowerCase()
                        .indexOf(_input.toLowerCase()) >= 0
                    }
                    filterSort={(_optionA: any, _optionB: any) =>
                      _optionA.children
                        .toLowerCase()
                        .localeCompare(_optionB.children.toLowerCase())
                    }
                  >
                    {_sedes.map((_sede: Sede, index: number) => {
                      return (
                        <Option
                          value={_sede.codigo}
                          key={`${index}-${_sede.codigo}`}
                        >
                          {_sede.nombre}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="politicas"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "boolean",
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox
                    checked={_politicasChecked}
                    onChange={(e: CheckboxChangeEvent) => {
                      _setPoliticasChecked(e.target.value);
                    }}
                  >
                    He le&iacute;do y acepto las &nbsp;
                    <a
                      href="https://www.3aamseq.com.pe/politicas/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Pol&iacute;ticas de privacidad
                    </a>
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="terminos"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor completa el campo!",
                      type: "boolean",
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox
                    checked={_terminosChecked}
                    onChange={(e: CheckboxChangeEvent) => {
                      _setTerminosChecked(e.target.value);
                    }}
                  >
                    He le&iacute;do y aceptado los &nbsp;
                    <a
                      href="https://concurso.3aamseq.com.pe/tyc/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      T&eacute;rminos y condiciones
                    </a>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="uso"
                  rules={[
                    {
                      required: false,
                      type: "boolean",
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox
                    checked={_usoChecked}
                    onChange={(e: CheckboxChangeEvent) => {
                      _setUsoChecked(e.target.value);
                    }}
                  >
                    Acepto el uso de mis datos personales para recibir
                    informaci&oacute;n sobre publicidad y promociones
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item>
                  <button className="btnRegistrarme">REGISTRARME</button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Space>
      </div>
    </Fragment>
  );
}
