import "./mis-datos.styles.css";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { Typography } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { Usuario } from "../../../model/usuario.model";

export default function MisDatos({
  usuario,
  editarUsuario,
}: {
  usuario: Usuario;
  editarUsuario: Function;
}) {
  const [_editar, _setEditar] = useState<boolean>(false);
  const [_form] = Form.useForm();
  const { Title } = Typography;

  useEffect(() => {
    _form.setFieldsValue({
      nombres: usuario.nombres,
      apellidos: usuario.apellidos,
      correo: usuario.email
        .substring(0, usuario.email.length / 3)
        .concat("****"),
      celular: usuario.celular,
      tipoDocumento: usuario.tipoDocumento,
      numeroDocumento: usuario.numeroDocumento
        .substring(0, usuario.numeroDocumento.length / 2)
        .concat("****"),
      fechaNacimiento: usuario.fechaNacimiento,
      departamento: usuario.distrito.provincia.departamento.nombre,
      sede: usuario.sede,
    });
  });

  const _onFinish = (e?: any) => {
    _form
      .validateFields()
      .then((e: any) => {
        _setEditar(false);
        editarUsuario(new Usuario(_form.getFieldsValue()));
      })
      .catch((error) => {});
  };

  return (
    <Fragment>
      <div className="div-mis-datos">
        <Form
          name="form"
          layout="vertical"
          onFinish={_onFinish}
          autoComplete="off"
          form={_form}
          requiredMark={false}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Nombres
                  </Title>
                }
                name="nombres"
                rules={[
                  {
                    required: true,
                    message: "¡Completa el campo!",
                    type: "string",
                  },
                ]}
              >
                <Input disabled={!_editar} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Apellidos
                  </Title>
                }
                name="apellidos"
                rules={[
                  {
                    required: true,
                    message: "¡Completa el campo!",
                    type: "string",
                  },
                ]}
              >
                <Input disabled={!_editar} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Correo electr&oacute;nico
                  </Title>
                }
                name="correo"
              >
                <Input disabled suffix={<LockOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Celular
                  </Title>
                }
                name="celular"
                rules={[
                  {
                    required: true,
                    message: "¡Completa el campo!",
                  },
                ]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                  type="number"
                  disabled={!_editar}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Tipo de documento
                  </Title>
                }
                name="tipoDocumento"
              >
                <Input disabled suffix={<LockOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    N&uacute;mero de documento
                  </Title>
                }
                name="numeroDocumento"
              >
                <Input disabled suffix={<LockOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Fecha de nacimiento
                  </Title>
                }
                name="fechaNacimiento"
              >
                <Input disabled suffix={<LockOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Departamento
                  </Title>
                }
                name="departamento"
              >
                <Input disabled suffix={<LockOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={
                  <Title level={5} className="form-label">
                    Sede
                  </Title>
                }
                name="sede"
              >
                <Input disabled suffix={<LockOutlined />} />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="col-btn-editar"
            >
              <Form.Item>
                <button
                  className="btnEditar"
                  type="button"
                  onClick={
                    _editar
                      ? () => {
                          _onFinish();
                        }
                      : () => {
                          _setEditar(true);
                        }
                  }
                >
                  {_editar ? "GUARDAR" : "EDITAR MIS DATOS"}
                </button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
}
