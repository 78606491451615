import { APIResponse } from "../../model/apiresponse";
import { APIUbigeo } from "../../api/ubigeo.api";
import { APIUsuario } from "../../api/usuario.api";
import { Departamento } from "../../model/departamento.model";
import { Distrito } from "../../model/distrito.model";
import { Fragment } from "react";
import { Layout } from "antd";
import { Modal } from "antd";
import { Provincia } from "../../model/provincia.model";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario.model";
import FloatingButton from "../widget/fab/fab.widget";
import Footer from "../widget/footer/footer.widget";
import Header from "../widget/header/header.widget";
import Registro from "../components/registro/registro.component";
import UbigeoContext from "../../provider/ubigeo_provider";
import UsuarioContext from "../../provider/usuario_provider";

export default function RegistroPage() {
  const [_departamentos, _setDepartamentos] = useState<Departamento[]>([]);
  const [_distritos, _setDistritos] = useState<Distrito[]>([]);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_provincias, _setProvincias] = useState<Provincia[]>([]);
  const _apiUbigeo = useContext<APIUbigeo>(UbigeoContext);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { Content } = Layout;
  let _navigate = useNavigate();

  const _goLogin = () => {
    _navigate("/");
  };

  const _listarDistritos = useCallback(
    async (provincia: number) => {
      _setDistritos([]);
      await _apiUbigeo._distritos(provincia).then((data: Distrito[] | null) => {
        _setDistritos(data!);
      });
    },
    [_apiUbigeo, _setDistritos]
  );

  const _listarProvincias = useCallback(
    async (departamento: number) => {
      _setProvincias([]);
      _setDistritos([]);
      await _apiUbigeo
        ._provincias(departamento)
        .then((data: Provincia[] | null) => {
          _setProvincias(data!);
          _listarDistritos(data![0].codigo);
        });
    },
    [_apiUbigeo, _listarDistritos]
  );

  const _listarDepartamentos = useCallback(async () => {
    _setDepartamentos([]);
    _setProvincias([]);
    _setDistritos([]);
    await _apiUbigeo._departamentos().then((data: Departamento[] | null) => {
      _setDepartamentos(data!);
      _listarProvincias(data![0].codigo);
    });
  }, [_apiUbigeo, _listarProvincias]);

  const _registrarUsuario = (usuario: Usuario) => {
    _modal.confirm({
      title: "3A AMSEQ",
      content: "¿Está seguro de registrarse?",
      centered: true,
      okText: "Si, registrarme",
      cancelText: "No, cancelar",
      onOk: async () => {
        let _response: APIResponse | null = await _apiUsuario._registrarUsuario(
          usuario
        );
        if (_response?.success) {
          _modal.success({
            title: "3A AMSEQ",
            content: _response.message,
            centered: true,
            onOk: () => {
              _goLogin();
            },
          });
        } else {
          _modal.error({
            title: "3A AMSEQ",
            content: _response?.message,
            centered: true,
          });
        }
      },
    });
  };

  const _getCurrentUser = useCallback(async () => {
    let _response: APIResponse | null = await _apiUsuario._getCurrentUser();
    if (_response?.success) {
      _navigate("/zona-usuario");
    }
  }, [_apiUsuario, _navigate]);

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    document.title = "3A AMSEQ - Registro";
    _listarDepartamentos();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser, _listarDepartamentos]);

  if (_mounted === null) {
    return <p>Cargando...</p>;
  }

  if (!_mounted) {
    return <p>Error</p>;
  }

  return (
    <Fragment>
      <Layout>
        <Header />
        <Content>
          <Registro
            departamentos={_departamentos}
            provincias={_provincias}
            distritos={_distritos}
            listarProvincias={_listarProvincias}
            listarDistritos={_listarDistritos}
            registrarUsuario={_registrarUsuario}
            goLogin={_goLogin}
          />
        </Content>
        <Footer />
        <FloatingButton />
      </Layout>
      {_contextHolder}
    </Fragment>
  );
}
