import "./login.styles.css";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Row } from "antd";
import { Spin } from "antd";
import { Typography } from "antd";
import Global from "../../../global/global";

export default function Login({
  login,
  goRegistro,
  spinner,
  isMobile,
}: {
  login: Function;
  goRegistro: Function;
  spinner: boolean;
  isMobile: boolean;
}) {
  const { Title } = Typography;
  const [_form] = Form.useForm();

  const _onFinish = (e: any) => {
    login(_form.getFieldValue("documento"));
  };

  return (
    <Fragment>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="col-form"
        >
          <Spin spinning={spinner}>
            <Form
              name="form"
              layout="vertical"
              onFinish={_onFinish}
              autoComplete="off"
              form={_form}
              requiredMark={false}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Title level={2} className="title">
                    CON 3A AMSEQ GANA M&Aacute;S EN CADA COMPRA
                  </Title>
                  <Title level={4} className="subtitle">
                    PORQUE PUEDES LLEVARTE UNA DE LAS
                  </Title>
                  <img
                    src={`${Global.URL_ASSETS}/premio.png`}
                    alt="3AAMSEQ SA"
                    title="3AAMSEQ"
                    className="img-premio"
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label={
                      <Title level={5}>
                        N&uacute;mero de documento de identidad
                      </Title>
                    }
                    name="documento"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor completa el campo!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="N° de documento"
                      size="large"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    <Row className="row-buttons">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={12}
                        className="col-buttons"
                      >
                        <button className="btnIngresar">INGRESAR</button>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={12}
                        className="col-buttons"
                      >
                        <button
                          className="btnRegistrarme"
                          onClick={() => {
                            goRegistro();
                          }}
                        >
                          REGISTRARME
                        </button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <img
            src={`${Global.URL_ASSETS}/promocion.jpg`}
            alt="3AAMSEQ SA"
            title="3AAMSEQ"
            style={{
              width: "100%",
              minHeight: isMobile ? "unset" : "calc(100vh - 200px)",
              height: "100%",
            }}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
