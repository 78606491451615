import { AxiosResponse } from "axios";
import { Departamento } from "../model/departamento.model";
import { DepartamentoDataI } from "../model/departamento.model";
import { Distrito } from "../model/distrito.model";
import { DistritoDataI } from "../model/distrito.model";
import { Provincia } from "../model/provincia.model";
import { ProvinciaDataI } from "../model/provincia.model";
import axios from "axios";
import Global from "../global/global";

export class APIUbigeo {
  async _departamentos(): Promise<Departamento[] | null> {
    let result: AxiosResponse = await axios.get(
      `${Global.URL_API}/listarDepartamentos`
    );
    if (result.status === 200) {
      let departamentos: Departamento[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: DepartamentoDataI) => {
          departamentos.push(new Departamento(element));
        });
      }
      return departamentos;
    }
    return null;
  }

  async _provincias(departamento: number): Promise<Provincia[] | null> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarProvincias`,
      {
        departamento,
      }
    );
    if (result.status === 200) {
      let listaProvincias: Provincia[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: ProvinciaDataI) => {
          listaProvincias.push(new Provincia(element));
        });
      }
      return listaProvincias;
    }
    return null;
  }

  async _distritos(provincia: number): Promise<Distrito[] | null> {
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarDistritos`,
      {
        provincia,
      }
    );
    if (result.status === 200) {
      let listaDistritos: Distrito[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: DistritoDataI) => {
          listaDistritos.push(new Distrito(element));
        });
      }
      return listaDistritos;
    }
    return null;
  }
}
