export interface CuponDataI {
  codigo: string;
  entry: string;
  cupon: string;
  estado: string;
  opciones: number;
  registro: string;
  actualizacion: string;
}

export class Cupon implements CuponDataI {
  codigo: string;
  entry: string;
  cupon: string;
  estado: string;
  opciones: number;
  registro: string;
  actualizacion: string;

  constructor(data: CuponDataI) {
    this.codigo = data.codigo;
    this.entry = data.entry;
    this.cupon = data.cupon;
    this.estado = data.estado;
    this.opciones = data.opciones;
    this.registro = data.registro;
    this.actualizacion = data.actualizacion;
  }
}
