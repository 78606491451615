export interface DepartamentoDataI {
  codigo: number;
  nombre: string;
}

export class Departamento implements DepartamentoDataI {
  codigo: number;
  nombre: string;

  constructor(data: DepartamentoDataI) {
    this.codigo = data.codigo;
    this.nombre = data.nombre;
  }
}
