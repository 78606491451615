import "./zona-usuario.styles.css";
import { Col } from "antd";
import { Space } from "antd";
import { Fragment } from "react";
import { useState } from "react";
import { Row } from "antd";
import { Tabs } from "antd";
import { Typography } from "antd";
import { Usuario } from "../../../model/usuario.model";
import Cupones from "../cupones/cupones.component";
import MisDatos from "../mis-datos/mis-datos.component";
import { LogoutOutlined } from "@ant-design/icons";
import { Cupon } from "../../../model/cupon.model";

export default function ZonaUsuario({
  usuario,
  editarUsuario,
  registrarCupon,
  listaCupones,
  logout,
}: {
  usuario: Usuario;
  editarUsuario: Function;
  registrarCupon: Function;
  listaCupones: Cupon[];
  logout: Function;
}) {
  const { Title } = Typography;
  const [_activeKey, _setActiveKey] = useState<string>("1");
  const [_title, _setTile] = useState<string>("MI PERFIL DE USUARIO");

  return (
    <Fragment>
      <div className="div-zona-usuario">
        <div className="container">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Space direction="vertical">
                <Title level={3} className="title">
                  {_title}
                </Title>
                <button
                  className="btnLogout"
                  onClick={() => {
                    logout();
                  }}
                >
                  <Space>
                    <LogoutOutlined />
                    {usuario ? usuario.nombres : ""}
                  </Space>
                </button>
              </Space>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Tabs
                defaultActiveKey={_activeKey}
                centered
                onChange={(activeKey: string) => {
                  _setActiveKey(activeKey);
                  _setTile(
                    activeKey === "1"
                      ? "MI PERFIL DE USUARIO"
                      : "MI ZONA DE USUARIO"
                  );
                }}
                items={[
                  {
                    label: (
                      <Title level={4} className="subtitle">
                        Registro de cupones
                      </Title>
                    ),
                    key: "1",
                    children: <Cupones registrarCupon={registrarCupon} listaCupones={listaCupones} />,
                  },
                  {
                    label: (
                      <Title level={4} className="subtitle">
                        Mis datos
                      </Title>
                    ),
                    key: "2",
                    children: (
                      <MisDatos
                        usuario={usuario}
                        editarUsuario={editarUsuario}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
}
