import "./cupones.styles.css";
import { DatePicker, Input, Row } from "antd";
import { Col } from "antd";
import { Fragment } from "react";
import { Typography } from "antd";
import { Form } from "antd";
import { Cupon } from "../../../model/cupon.model";
import locale from "antd/es/date-picker/locale/es_ES";

export default function Cupones({
  registrarCupon,
  listaCupones,
}: {
  registrarCupon: Function;
  listaCupones: Cupon[];
}) {
  const { Title } = Typography;
  const [_form] = Form.useForm();

  const _onFinish = (e: any) => {
    registrarCupon(
      _form.getFieldValue("cupon"),
      _form.getFieldValue("monto"),
      _form.getFieldValue("fecha"),
      _form.getFieldValue("entry")
    );
  };

  return (
    <Fragment>
      <div className="div-cupones">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Title level={2} className="title">
              ¿Tienes un cup&oacute;n?
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form
              name="form"
              layout="vertical"
              onFinish={_onFinish}
              autoComplete="off"
              form={_form}
              requiredMark={false}
            >
              <Form.Item
                label={<Title level={5}>Fecha</Title>}
                name="fecha"
                rules={[
                  {
                    required: true,
                    message: "¡Por favor completa el campo!",
                  },
                ]}
              >
                <DatePicker
                  locale={locale}
                  format={"YYYY-MM-DD"}
                  inputReadOnly
                />
              </Form.Item>

              <Form.Item
                label={<Title level={5}>Monto</Title>}
                name="monto"
                rules={[
                  {
                    required: true,
                    message: "¡Por favor completa el campo!",
                  },
                ]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[\w.-]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                  type="number"
                />
              </Form.Item>

              <Form.Item
                label={<Title level={5}>C&oacute;digo Doc.</Title>}
                name="entry"
                rules={[
                  {
                    required: true,
                    message: "¡Por favor completa el campo!",
                  },
                ]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onWheel={(event) => event.currentTarget.blur()}
                  type="number"
                />
              </Form.Item>

              <Form.Item
                label={<Title level={5}>Cup&oacute;n</Title>}
                name="cupon"
                rules={[
                  {
                    required: true,
                    message: "¡Por favor completa el campo!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <button className="btnEnviar">ENVIAR</button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <hr />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Title level={4} className="subtitle">
              Historial de cupones
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <table>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Cup&oacute;n</th>
                  <th>Opciones</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {listaCupones.map((value: Cupon, index: number) => {
                  return (
                    <tr key={`${value.cupon}_${index}`}>
                      <td>{value.registro.substring(0, 10)}</td>
                      <td className="cupon">{value.cupon}</td>
                      <td>{value.opciones}</td>
                      <td
                        className={
                          value.estado === "VALIDO"
                            ? "estado-valido"
                            : "estado-no-valido"
                        }
                      >
                        {value.estado}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}
