import { Departamento } from "./departamento.model";

export interface ProvinciaDataI {
  codigo: number;
  nombre: string;
  departamento: Departamento;
}

export class Provincia implements ProvinciaDataI {
  codigo: number;
  nombre: string;
  departamento: Departamento;

  constructor(data: ProvinciaDataI) {
    this.codigo = data.codigo;
    this.nombre = data.nombre;
    this.departamento = data.departamento;
  }
}
