import { APIResponse } from "../../model/apiresponse";
import { APIUsuario } from "../../api/usuario.api";
import { Fragment } from "react";
import { Layout } from "antd";
import { Modal } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import FloatingButton from "../widget/fab/fab.widget";
import Footer from "../widget/footer/footer.widget";
import Header from "../widget/header/header.widget";
import Login from "../components/login/login.component";
import UsuarioContext from "../../provider/usuario_provider";

export default function LoginPage({ isMobile }: { isMobile: boolean }) {
  const { Content } = Layout;
  const [_mounted, _setMounted] = useState<any>(null);
  const [_spinner, _setSpinner] = useState<boolean>(true);
  let _navigate = useNavigate();
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const [_modal, _contextHolder] = Modal.useModal();

  const _goRegistro = () => {
    _navigate("/registro");
  };

  const _login = async (documento: string) => {
    _setSpinner(true);
    let _response: APIResponse | null = await _apiUsuario._login(documento);
    if (_response?.success) {
      _navigate("/zona-usuario");
    } else {
      _modal.error({
        title: "3A AMSEQ",
        content: _response?.message,
        centered: true,
        onOk: () => {
          _setSpinner(false);
        },
      });
    }
  };

  const _getCurrentUser = useCallback(async () => {
    let _response: APIResponse | null = await _apiUsuario._getCurrentUser();
    if (_response?.success) {
      _navigate("/zona-usuario");
    } else {
      _setSpinner(false);
    }
  }, [_apiUsuario, _navigate]);

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    document.title = "3A AMSEQ - Login";
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser]);

  if (_mounted === null) {
    return <p>Cargando...</p>;
  }

  if (!_mounted) {
    return <p>Error</p>;
  }
  return (
    <Fragment>
      <Layout>
        <Header />
        <Content>
          <Login
            login={_login}
            goRegistro={_goRegistro}
            spinner={_spinner}
            isMobile
          />
        </Content>
        <Footer />
        <FloatingButton />
      </Layout>
      {_contextHolder}
    </Fragment>
  );
}
