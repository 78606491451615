import { APIResponse } from "../model/apiresponse";
import { APIUsuario } from "./usuario.api";
import { AxiosResponse } from "axios";
import { Cupon } from "../model/cupon.model";
import { CuponDataI } from "../model/cupon.model";
import axios from "axios";
import Global from "../global/global";

export class APICupon {
  async _registrarCupon(
    codigo: string,
    monto: number,
    fecha: string,
    entry: string
  ): Promise<APIResponse | null> {
    let apiUsuario: APIUsuario = new APIUsuario();
    let token: string = await apiUsuario.getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/registrarCupon`,
      { token, codigo, monto, fecha, entry }
    );
    if (result.status === 200) {
      return new APIResponse(result.data);
    }
    return null;
  }

  async _listarCupones(): Promise<Cupon[] | null> {
    let apiUsuario: APIUsuario = new APIUsuario();
    let token: string = await apiUsuario.getToken();
    let result: AxiosResponse = await axios.post(
      `${Global.URL_API}/listarCupones`,
      {
        token,
      }
    );
    if (result.status === 200) {
      let cupones: Cupon[] = [];
      if (result.data.success) {
        result.data.data.forEach((element: CuponDataI) => {
          cupones.push(new Cupon(element));
        });
      }
      return cupones;
    }
    return null;
  }
}
