import { Provincia } from "./provincia.model";

export interface DistritoDataI {
  codigo: number;
  nombre: string;
  provincia: Provincia;
}

export class Distrito implements DistritoDataI {
  codigo: number;
  nombre: string;
  provincia: Provincia;

  constructor(data: DistritoDataI) {
    this.codigo = data.codigo;
    this.nombre = data.nombre;
    this.provincia = data.provincia;
  }
}
