export interface SedeDataI {
  codigo: string;
  nombre: string;
}

export class Sede implements SedeDataI {
  codigo: string;
  nombre: string;

  constructor(data: SedeDataI) {
    this.codigo = data.codigo;
    this.nombre = data.nombre;
  }
}
