import { Layout } from "antd";
import { Col } from "antd";
import { Row } from "antd";
import { Fragment } from "react";
import logo_ from "../../../assets/logo_.svg";
import { Space } from "antd";
import { YoutubeFilled } from "@ant-design/icons";
import { InstagramFilled } from "@ant-design/icons";
import { FacebookFilled } from "@ant-design/icons";
import "./footer.styles.css";

export default function Footer() {
  const { Footer } = Layout;

  return (
    <Fragment>
      <Footer>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            className="col-logo-footer"
          >
            <img
              src={logo_}
              alt="3AAMSEQ SA"
              title="3AAMSEQ"
              style={{ width: 200 }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            className="col-links-footer"
          >
            <Space direction="vertical">
              <a
                href="https://concurso.3aamseq.com.pe/tyc/"
                rel="noreferrer"
                target="_blank"
              >
                T&eacute;rminos y condiciones
              </a>
              <a
                href="https://www.3aamseq.com.pe/politicas/"
                rel="noreferrer"
                target="_blank"
              >
                Pol&iacute;ticas de tratamiento de datos
              </a>
            </Space>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            className="col-icons-footer"
          >
            <Space>
              <a
                href="https://www.instagram.com/3aamseqperu/"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramFilled className="icon-footer" />
              </a>
              <a
                href="https://www.facebook.com/3AAMSEQPERU"
                rel="noreferrer"
                target="_blank"
              >
                <FacebookFilled className="icon-footer" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCO43nSLPFmLIlEZQwHK7B3w"
                rel="noreferrer"
                target="_blank"
              >
                <YoutubeFilled className="icon-footer" />
              </a>
            </Space>
          </Col>
        </Row>
      </Footer>
    </Fragment>
  );
}
